
import axios from 'axios'

export default {
  namespace: 'wechatAccounts',
  state: [
  ],
  effects: {
    *loadAccounts({ payload }, { call, put }) {
      const res = yield call(axios.get, '/yaoyu/getKfAccountsList');
      if(res.data.errno === 0) {
        return yield put({
          type: 'setUsers',
          payload: res.data.accounts
        });
      } else {
        message.error('无法加载客服列表：' + res.data.msg);
      }
    }
  },
  reducers: {
    setUsers(state, { payload: users }) {
      return users;
    }
  }
}