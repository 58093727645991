import dva from 'dva';
import { Component } from 'react';
import createLoading from 'dva-loading';
import history from '@tmp/history';

let app = null;

export function _onCreate() {
  const plugins = require('umi/_runtimePlugin');
  const runtimeDva = plugins.mergeConfig('dva');
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    ...(window.g_useSSR ? { initialState: window.g_initialData } : {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach(plugin => {
    app.use(plugin);
  });
  
  app.model({ namespace: 'accounts', ...(require('/root/workspace/models/accounts.js').default) });
app.model({ namespace: 'csOrders', ...(require('/root/workspace/models/csOrders.js').default) });
app.model({ namespace: 'eventLog', ...(require('/root/workspace/models/eventLog.js').default) });
app.model({ namespace: 'exchangeOrder', ...(require('/root/workspace/models/exchangeOrder.js').default) });
app.model({ namespace: 'fans', ...(require('/root/workspace/models/fans.js').default) });
app.model({ namespace: 'goods', ...(require('/root/workspace/models/goods.js').default) });
app.model({ namespace: 'index', ...(require('/root/workspace/models/index.js').default) });
app.model({ namespace: 'liveFomCard', ...(require('/root/workspace/models/liveFomCard.js').default) });
app.model({ namespace: 'menu', ...(require('/root/workspace/models/menu.js').default) });
app.model({ namespace: 'notice', ...(require('/root/workspace/models/notice.js').default) });
app.model({ namespace: 'order', ...(require('/root/workspace/models/order.js').default) });
app.model({ namespace: 'orders', ...(require('/root/workspace/models/orders.js').default) });
app.model({ namespace: 'products', ...(require('/root/workspace/models/products.js').default) });
app.model({ namespace: 'session', ...(require('/root/workspace/models/session.js').default) });
app.model({ namespace: 'shoppingCart', ...(require('/root/workspace/models/shoppingCart.js').default) });
app.model({ namespace: 'wechatAccounts', ...(require('/root/workspace/models/wechatAccounts.js').default) });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
