import * as services from '../services/index'; 

export default {
  namespace: 'index',
  state: {
    accountsFetching: false, // 微信客服列表加载状态
    accounts: [], // 微信客服列表
    saleUsersFetching: false, // 销售列表加载状态
    saleUsers: [], // 销售列表
    payments: [], // 支付方式
  },
  reducers: {
    setAccounts(state, { payload }) {
      return {
        ...state,
        accounts: payload
      }
    },
    setAccountsFetching(state, { payload }) {
      return {
        ...state,
        accountsFetching: payload
      }
    },
    setSaleUsers(state, { payload }) {
      return {
        ...state,
        saleUsers: payload
      }
    },
    setSaleUsersFetching(state, { payload }) {
      return {
        ...state,
        saleUsersFetching: payload
      }
    },
    setPayments(state, { payload }) {
      return {
        ...state,
        payments: payload
      }
    }
  },
  effects: {
    *getAccounts({ payload }, { call, put, select }) {
      yield put({
        type: 'setAccountsFetching',
        payload: true
      });

      const { data } = yield call(services.getAccounts, payload);

      yield put({
        type: 'setAccountsFetching',
        payload: false
      });
      
      if (data.errno === 0) {
        yield put({
          type: 'setAccounts',
          payload: data.accounts
        });
      }
    },
    *getSaleUsers({ payload }, { call, put, select }) {
      yield put({
        type: 'setSaleUsersFetching',
        payload: true
      });

      const { data } = yield call(services.getSaleUsers, payload);

      yield put({
        type: 'setSaleUsersFetching',
        payload: false
      });

      if (data.errno === 0) {
        yield put({
          type: 'setSaleUsers',
          payload: data.users
        });
      }
    },
    *getPayments({ payload }, { call, put, select }) {
      const { data } = yield call(services.getPayments);
      console.log(data);
      if (data.errno === 0) {
        yield put({
          type: 'setPayments',
          payload: data.payments
        })
      }
    }
  }
}