const searchAndModify = (index, obj) =>  (item, i) => {
  if(i === index) {
    if(Number.isInteger(obj.number) && !obj.number) {
      return false;
    }
    return Object.assign(item, obj);
  } else {
    return item;
  }
};

export default {
  namespace: 'shoppingCart',
  state: [],
  reducers: {
    setState(state, { payload }) {
      return Object.assign({}, state, payload);
    },
    clearAll(state, { payload: id }) {
      return [];
    },
    addToCart(state, { payload }) {
      let processed = false;
      let good_id = payload.good_id;
      if(payload.good && payload.good.good_id) {
        good_id = payload.good.good_id;
        payload.good_id = good_id;
      }
      for(let item of state) {
        if(item.good_id === payload.good_id && payload.price === item.price && payload.type === item.type) {
          item.number += payload.number;
          processed = true;
          break;
        }
      }
      if(!processed) {
        state.push({
          ...payload
        });
      }
      return [...state];
    },
    modifyNumberInCart(state, { payload }) {
      const { item, number } = payload;
      const index = state.indexOf(item);

      const results = state.map(searchAndModify(index, {
        number
      }))
      .filter(item => item !== false);
      return [...results];
    },
    modifyPriceInCart(state, { payload }) {
      const { item, price } = payload;
      const index = state.indexOf(item);
      console.log('modifyPriceInCart', index, price);
      const results = state.map(searchAndModify(index, {
          customPrice: price 
        }))
        .filter(item => item !== false);
      return [...results];
    }
  }
}