import axios from 'axios';
import { message } from 'antd';
export default {
  namespace: 'fans',
  state: {
    category:[]
  },
  effects: {

  },
  reducers: {
    setState(state, { payload }) {
        return Object.assign({}, state, payload);
      },
  }
};