import moment from 'moment';

export default {
  namespace: 'csOrders',
  state:{
    tableDateRanges: [ moment().startOf('isoWeek'), moment() ],
    data: [],
    nextPage:false,
    nextPageData:[],
    pagination: {
      current:1
    },
    data: [],
    csStatus:[],
    showCsRank:'',
    orderDateRanges:[],
      gid:'',
      no: '',
      supplierId: '',
      csType: '',
      reasonCategory:'',
      reason:'',
      status:'',
      orderId:'',
      saleName:'',
      kfName:'',
      buyerName:'',
      buyerPhone:'',
      buyerAfterSalesNo:'',
      chargePerson:'',
      reasonCategoryList:[],
      reasonList:[]
  },
  reducers: {
    setState(state, { payload }) {
      Object.assign(state, payload);
      return {
        ...state
      }
    },
  }
}