import styles from './index.less'
import router from "umi/router";
import DrawerCusInfo from './drawerCusinfo'
import { connect } from 'dva';
import {
    Drawer,
    message,
    Popconfirm
} from 'antd'
import Axios from 'axios';
import React from 'react';

const notVar = /[^\w]/

/**
 * @description 配置规则，定义消息列表信息
 * @param {String} icon 显示图标,从iconfont获取
 * @param {String} text 显示文案（内容）。变量使用%%进行包裹,状态使用[]进行包裹
 * @param {String|String[]} action 操作采取的行动，枚举类型 @enum {link|pop}
 * 当 action 值为string时，所有变量的点击行为都匹配为action
 * 当 action 值为array时，对应下标变量的点击行为匹配为对应下标的action
 * @param {Function} final action执行完毕后进行的操作
 */
const noticeMap = new Map([
    ['售后单揽收延迟', {
        icon: '',
        text: '你有%type%订单：%order_no%, 超过%delay_days%天仓库未揽收，请核实仓库是否已收到货物，以及客户余额使用是否正常。',
        action: [,'link:/orders?order_id=%order_no%',],
        final: (row, status) => ({
            method: 'post',
            url: '/api/manage/users/undoMsgRead',
            params: {
                notice_id: row.notice_id
            },
            isUseStatus: false
        })
    }],
    ['财务审核', {
        icon: 'icon-caiwushenhe',
        text: '你有订单号：%order_no%[read]财务审核',
        action: 'link:/orders?order_id=%order_no%'
    }],
    ['财务审核驳回', {
        icon: 'icon-bohui',
        text: '你有订单号：%order_no%被驳回，驳回理由：%remark%',
        action: ['link:/orders?order_id=%order_no%',]
    }],
    ['客户审核不通过', {
        icon: 'icon-kehushenhe',
        text: '你有客户：%customer_name%审核驳回，驳回理由：%remark%，请尽快处理',
        action: ['link:/crm/client/detail/%parent_id%',]
    }],
    ['直播订单同步', {
        icon: 'icon-zhibotongbu',
        text: '你有订单号：%order_no%,从直播间同步至OMS系统，请查看',
        action: 'link:/orders?order_id=%order_no%',
        final: (row, status) => ({
            method: 'post',
            url: '/api/manage/users/undoMsgRead',
            params: {
                notice_id: row.notice_id
            },
            isUseStatus: false
        })
    }],
    ['生日提醒', {
        icon: 'icon-shengritixing',
        text: '你的客户%customer_name%今日过生日，请尽快去问候',
        action: 'link:/manage/crmusers?crm_uid=%parent_id%',
        final: (row, status) => ({
            method: 'post',
            url: '/api/manage/users/undoMsgRead',
            params: {
                notice_id: row.notice_id
            },
            isUseStatus: false
        })
    }],
    ['会员认领', {
        icon: 'icon-huiyuanrenling',
        text: '你有【%saler_name%】下【%kf_account%】发起的客户名称：{%crm_user_name%}、微信号：{%wechat%}的会员[claim_status]',
        action: [, , 'pop', 'pop'],
        final: (row, status) => ({
            method: 'post',
            url: '/api/manage/claim-crm-users/claim',
            params: {
                claim_id: row.parent_id,
                status: status ? '已认领' : '已拒绝'
            },
            toast: status ? '认领成功' : '已拒绝',
            isUseStatus: true
        }),
        popconfirm: {
            title: '是否接收客户?',
            okText: '接收',
            cancelText: '拒绝'
        }
    }],
    ['会员待完善', {
        icon: 'icon-huiyuandaiwanshan',
        text: '你有买家ID%contact_unique_id%，%customer_name%的客户[read]完善',
        action: 'link:/manage/crmusers?crm_uid=%parent_id%'
    }],
    ['订单认领', {
        icon: 'icon-dingdanrenling',
        text: '你有客户名称：%customer_name% [order_status]导入，订单号：%order_no%，新签销售【%saler_name%】，微信客服：%kf_name%',
        action: ['drawer', ,],
        final: (row, status) => ({
            method: 'post',
            url: '/api/manage/users/undoMsgRead',
            params: {
                notice_id: row.notice_id
            },
            isUseStatus: false
        })
    }],
    ['新签销售订单', {
        icon: 'icon-xinqian',
        text: '你有新签销售【%saler_name%】的客户【%customer_name%】购买的新订单%order_no%，请查看',
        action: [, , 'link:/orders?order_id=%order_no%'],
        final: (row, status) => ({
            method: 'post',
            url: '/api/manage/users/undoMsgRead',
            params: {
                notice_id: row.notice_id
            },
            isUseStatus: false
        })
    }]
])

const dynamicVarMap = new Map([
    ['claim_status', {
        '已拒绝': '已拒绝',
        '未认领': '待认领',
        '已认领': '已认领'
    }],
    ['order_status', {
        '认领成功': '已',
        '认领失败': '待',
        '未认领': '待'
    }],
    ['read', {
        '已读': '已',
        '未读': '待'
    }]
])

// 解析action
const getAction = (action, row) => {
    const isLink = /link/
    const isPop = /pop/
    const isDrawer = /drawer/

    let res = {
        type: '',
        content: ''
    }

    // 操作为链接跳转
    if (isLink.test(action)) {
        let linkStr = action.match(/link:(.*)/)?.[1] || action
        linkStr = String(linkStr).replace(/%([\w]+)%/, (match, val) => row[val])

        // 结果赋值
        res = { type: 'link', content: linkStr }
    }

    // 操作为弹窗
    else if (isPop.test(action)) {

        let statusKey = action.match(/pop:(.*)/)?.[1] || action

        statusKey = statusKey.replace(/%/g, '')

        // 如果该action的状态值为已完成，返回undefined用于下一步处理时返回普通html片段
        if (!notVar.test(statusKey) && row[statusKey] === '认领成功')
            return undefined

        res = { type: 'pop', content: '' }
    }

    // 操作为抽屉
    else if (isDrawer.test(action)) {
        res = { type: 'drawer', content: '' }
    }

    return res
}

const mapStateToProps = state => {
    return {
        loading: state.notice.loading,
        listTotal: state.notice.listTotal,
        listUntreated: state.notice.listUntreated,
        total: state.notice.total,
        isDrawerVisible: state.notice.isDrawerVisible
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getNoticeList: (questType, pageSize, currentPage) => {
            dispatch({
                type: 'notice/getNoticeList',
                payload: { questType, pageSize, currentPage },
            });
        },
        reGetList: () => {
            dispatch({
                type: 'notice/reGetList'
            })
        },
        setIsDrawerVisible: (visible) => {
            dispatch({
                type: 'notice/setState',
                payload: { isDrawerVisible: visible }
            })
        }
    }
}
class NoticeChild extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isDrawerVisible: false
        }
    }

    static noticeMap = noticeMap
    static getAction = getAction

    // 创建HTML片段
    getHtml = (config, row, action) => {

        const { isDrawerVisible } = this.state
        const { final, popconfirm = {} } = config

        // 获取内容切割数组
        const htmls = config?.text?.split('%') || []

        const isActionArray = Array.isArray(action)

        const isRead = row.read === '已读'

        const typeConfig = new Map([

            // 在点击后进行link类型操作的HTML片段
            /**
             * @param {Object} item 当前行的row对象
             * @param {String} content 跳转链接
             */
            ['link', (item, index, content) => (
                <span
                    key={`link${index}`}
                    className={styles['link']}
                    data-read={isRead}
                    onClick={() => { this.redirect(final, content, row) }}
                >
                    {item === 'remark' && row[item] === '' ? '无' : row[item]}
                </span>
            )],

            // 在点击后进行pop类型操作的HTML片段
            ['pop', (item, index, content) => (
                <Popconfirm
                    key={`pop${index}`}
                    title="确定操作"
                    okText="确定"
                    cancelText="取消"
                    {...popconfirm}
                    getPopupContainer={e => e.parentNode}
                    onConfirm={() => { this.onFinal(final, row, true) }}
                    onCancel={() => { this.onFinal(final, row, false) }}
                >
                    <span
                        className={styles['link']}
                        data-read={isRead}
                    >
                        {row[item]}
                    </span>
                </Popconfirm>
            )],

            // 在点击后进行drawer类型操作的HTML片段
            ['drawer', (item, index, content) => (
                <span>
                    <span
                        className={styles['link']}
                        data-read={isRead}
                        onClick={this.showDrawer}
                    >
                        {row[item]}
                    </span>
                    <Drawer
                        width={930}
                        title="客户信息表"
                        placement="right"
                        visible={isDrawerVisible}
                        onClose={this.hideDrawer}
                        destroyOnClose
                        className="notification-center-drawer"
                    >
                        <DrawerCusInfo
                            crmUid={row.crm_uid}
                            oid={row.parent_id}
                            noticeId={row.notice_id}
                            hideDrawer={this.hideDrawer}
                            finalAction={this.onFinal}
                            row={row}
                            final={final}
                        />
                    </Drawer>
                </span>
            )],

            // 没有任何操作的HTML片段
            [undefined, (item, index, content) => (
                <span key={`normal${index}`}>{item === 'remark' && !row[item] ? '无' : row[item]}</span>
            )]
        ])

        // 变量下标计数
        let varIndex = 0

        // 返回主体
        return htmls.map((item, index) => {
            let child = null

            // 判断，是变量类型
            if (!notVar.test(item)) {
                const parentData = isActionArray ? action[varIndex] : action
                const { type, content } = parentData || {}
                varIndex++

                child = typeConfig.get(type)(item, index, content)
            }
            // 非变量类型
            else {
                let itemTemp = item
                const isHasDynamicVar = (/\[.*?\]/).test(itemTemp)

                if (isHasDynamicVar) {
                    // 替换字符串中的状态值，状态值的映射关系存储于 dynamicVarMap 中
                    itemTemp = itemTemp.replace(/\[(.*?)\]/, (match, val) => {
                        const status = row[val]
                        const value = dynamicVarMap.get(val || 'read')
                        return value[status] || ''
                    })
                }

                child = (
                    <span key={index}>{itemTemp}</span>
                )
            }

            return child
        })
    }

    showDrawer = () => {
        this.setState({ isDrawerVisible: true })
        this.props.setIsDrawerVisible(true)
    }

    hideDrawer = () => {
        this.setState({ isDrawerVisible: false })
        this.props.setIsDrawerVisible(false)
    }

    // 跳转
    redirect = async (final, link, row) => {
        if (final) {
            await this.onFinal(final, row, true)
        }
        router.push(link)
    }

    /**
     * @description 最后执行的请求操作
     * @param {Function()} final
     * @param {*} row 
     * @param {*} status 
     * @returns 
     */
    onFinal = async (final, row, status) => {

        if (!final)
            return

        const { method = 'post', url, params, isUseStatus, toast } = final(row, status)

        // 如果不使用status判断气泡框点击了取消按钮的操作，并且当前气泡框点击了取消，屏蔽此次请求
        if (!isUseStatus && !status)
            return

        let res = null
        if (method === 'get') {
            res = await Axios.get(url, {
                params: { ...params }
            })
        } else {
            res = await Axios.post(url, {
                ...params
            })
        }

        const { errno, msg } = res.data
        if (errno === 0) {
            !!toast && message.success(toast)
            this.props.reGetList()
        } else {
            message.error(msg)
        }
    }

    render() {
        const { data, row, action } = this.props

        return this.getHtml(data, row, action)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoticeChild)