import axios from 'axios';
export default {
  namespace: 'menu',
  state: {
    selectedKeys: [],
    repairNum:0,
    group:{},
    onOpenMenu:["0", "1", "2"]
  },
  effects: {
    //仓库做异步处理，获取工单数量，外部直接调用函数
    *getRepairNum({ payload }, { call, put }) {
      const res = yield call(axios.get, '/api/manage/work-order/un-read-work');
      if(res.data.errno > 0) {
        return message.error(res.data.msg);
      } else {
        return yield put({
          type: 'setRepairNum',
          payload:res.data
        })
        
      }
    },
  },
  reducers: {
    onSelect(state, { payload }) {
      const { key } = payload;
      state.selectedKeys = [ key ];
      return Object.assign({}, state);
    },
    onOpenChangeMenu(state,  {payload} ) {
      state.onOpenMenu = payload;
      return Object.assign({}, state);
    },
    setRepairNum(state,{ payload }){
        state.repairNum = payload.num;
        state.group= payload.group;
      return Object.assign({}, state);
    }
  }
}