import React from "react";
import styles from './index.less'
import { Col, Row } from "antd";

const icon_dropdown = require('./icon/dropdown.png')

export default class FoldUnit extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isFold: false
        }
    }

    setIsFold = () => {
        const { isFold } = this.state
        this.setState({
            isFold: !isFold
        })
    }

    render() {

        const { isFold } = this.state

        /**
         * @param {ReactDOM} alwaysOn 常显项
         * @param {ReactDOM} folded 折叠项
         */
        const { title, subTitle, alwaysOn, folded, couldFold = false } = this.props

        return (
            <div className={styles['fold-unit']}>
                <span className={styles['unit-title']}>
                    {title}
                    {
                        subTitle && (
                            <span className={styles['unit-subtitle']}>{subTitle}</span>
                        )
                    }
                </span>
                <Row>
                    {alwaysOn}
                    {
                        folded && (folded?.props?.children.length !== 0) && (
                            <Col span={24}>
                                <span className={styles['dropdown']} onClick={() => { this.setIsFold() }}>
                                    <img className={styles['dropdown-icon']} src={icon_dropdown} alt="" data-fold={isFold} />
                                    <span className={styles['dropdown-text']}>{isFold ? '收起' : '展开'}</span>
                                </span>
                            </Col>
                        )
                    }
                </Row>
                <Row
                    data-show={isFold}
                >
                    {folded}
                </Row>
            </div>
        )
    }
}