import React from 'react';
import { Popover } from 'antd';

function PopoverImg(props) {
    const trigger = props.trigger ? props.trigger : 'click';
    const placement = props.placement ? props.placement : 'rightTop';
    const defaultTag = <img 
        src={`${props.src}`} 
        style={{maxHeight: 200, maxWidth: 120 }} />
    const { children = defaultTag } = props;
    return <Popover 
        overlayStyle={{position: 'fixed', top: 0}} 
        align={{useCssBottom: true}} 
        content={<img 
            style={{maxHeight: 600, maxWidth: 400}} 
            src={props.src} />} 
        trigger={trigger}
        placement={placement}>
        {children}
    </Popover>
}

export default PopoverImg;
