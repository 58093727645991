import axios from 'axios';
import { message } from 'antd';
import moment from 'moment';

const emptyState = {
  address: {},
  payType: '已付全款',
  prepayPrice: 0, //订金
  totalPrice: 0, //订单总金额
  price: 0, // 订单实际金额
  payment: '微信转账',
  account: '',
  uid: '',
  customerType: '非当天新用户',
  inputAddress: '',
  remark: '',
  orderDate: moment(),
  screenshot: '',
  img: '',
  from_record_id: 0
};

export default {
  namespace: 'exchangeOrder',
  state: {},
  effects: {
    *loadExchangeOrder({ payload: record_id }, effects) {
      const res = yield call(axios.get, '/yaoyu/getRecordContact?record_id=' + encodeURIComponent(record_id));
      if (res.data.errno > 0) {
        return message.error(res.data.msg);
      }
      yield put({  
        type: 'setState', 
        payload: res.data.order
      });
    }
  },
  reducers: {
    setState(state, { payload }) {
      return Object.assign({}, state, payload);
    },
    reset() {
      return Object.assign({}, emptyState);
    }
  }
}