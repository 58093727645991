import axios from 'axios';
import moment from 'moment';
const emptyState = {
  tableDateRanges: [],
  kfName: '',
  searchOrderId: '',
  searchExpressNo: '',
  searchPhone: '',
  contactId: '',
  searchName: '',
  searchUser: '',
  searchProduct: '',
  searchSupplier: '',
  express_status: [],
  csStatus:[],
  payment: '',
  gid: '',
  source: "",
  pagination: {},
  data: [],
  contacts: {},
  addresses: {},
  orderType:'',
  hasGift: '',
  liveOrderNo:'',
  isFirstPurchase:'',
  crmUid:''
};
export default {
  namespace: 'orders',
  state: JSON.parse(JSON.stringify(emptyState)),
  reducers: {
    addContact(state, { payload }) {
      state.contacts[payload.order_id] = payload;
      return Object.assign({}, state);
    },
    addAddress(state, { payload }) {
      state.addresses[payload.order_id] = payload;
      return Object.assign({}, state);
    },
    reloadAddress(state, { payload }) {
      state.addresses = payload;
      return Object.assign({}, state);
    },
    setState(state, { payload }) {
      return Object.assign({}, state, payload);
    },
    reset() {
      return JSON.parse(JSON.stringify(emptyState));
    },
    setOrderCostPrice(state, { payload }) {
      for(let order of state.data) {
        if (order.order_id === payload.orderId) {
          order.orders[payload.itemIndex].cost_price = payload.price;
          return Object.assign({}, state, {
            data: state.data
          });
        }
      }
      return state;
    }
  }
}