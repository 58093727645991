import { message } from "antd";
import Axios from "axios";
import NoticeChild from './../pages/notificationCenter/public'

const { noticeMap } = NoticeChild

export default {
    namespace: 'notice',
    state: {
        // 是否加载状态
        loading: false,
        // 全部消息
        listTotal: [],
        // 未处理消息
        listUntreated: [],
        // 总数据
        total: 0,
        // 每页条数
        pageSize: 10,
        // 当前页码
        currentPage: 1,
        // 请求type参数
        type: 'total',
        // 上一个标签类型
        lastQuestType: '',

        // 未处理消息，消息下拉无限滚动列表
        listUntreatedScroll: new Map(),
        totalScroll: 0,
        hasMore: false,
        scrollLoading: false,

        // 消息数量统计
        noticeNums: {
            signCount: 0,
            claimCount: 0,
            lockOrderNum: 0,
            undoOrderAuditNum: 0,
            undoFansAuditNum: 0,
            undoLiveOrderNum: 0,
            undoCrmUserPerfectNum: 0,
            undoCrmUserBirthNum: 0,
            undoCrmDelayNum: 0
        },

        // 抽屉是否打开
        isDrawerVisible: false,

        // 客户信息
        crmInfo: null,

        // 匹配到的复购销售数据
        repurchaseData: null
    },
    effects: {
        *reGetList({ payload }, { put, call, take }) {
            yield put({
                type: 'setState',
                payload: {
                    listUntreatedScroll: new Map(),
                    totalScroll: 0,
                    hasMore: false,
                    scrollLoading: false
                }
            })

            yield put({
                type: 'getNoticeList',
                payload: {
                    questType: 'scroll',
                    pageSize: 20,
                    currentPage: 1
                }
            })

            yield put({
                type: 'getNoticeList',
                payload: {
                    questType: 'total'
                }
            })

            yield put({
                type: 'getNoticeList',
                payload: {
                    questType: 'untreated'
                }
            })

            yield put({
                type: 'getNoticeNum'
            })
        },
        /**
         * @param {String} questType total | untreated | scroll
         * @param {Number} pageSize 
         * @param {Number} currentPage 
         */
        *getNoticeList({ payload }, { call, put, select }) {
            let { currentPage: page, pageSize: page_size, questType } = payload

            // 获取state中的数据
            const state = yield select(state => state.notice)
            const { pageSize: statePageSize, currentPage: stateCurrentPage, type: stateType, listUntreatedScroll, lastQuestType, totalScroll } = state

            // 如果tab没有传值，则使用历史值
            questType = !!questType ? questType : lastQuestType

            const type = questType === 'total' ? undefined : '未读'

            yield put({
                type: 'setState',
                payload: {
                    loading: true,
                    type,
                    lastQuestType: questType
                }
            })

            const res = yield call(
                Axios.post,
                '/api/manage/notices/get-list',
                {
                    type,
                    page_size: page_size || statePageSize,
                    page: page || stateCurrentPage
                }
            )

            let { errno, msg, list, count: total, page: currentPage, size: pageSize } = res.data

            if (errno === 0) {

                list = list.filter(item => noticeMap.has(item.notice_type))

                // 为scroll类型，无限滚动
                if (questType === 'scroll') {
                    let newList = (totalScroll === total) ? listUntreatedScroll : new Map()
                    list.forEach(item => {
                        newList.set(item.notice_id, item)
                    })

                    yield put({
                        type: 'setState',
                        payload: {
                            listUntreatedScroll: newList,
                            totalScroll: total,
                            hasMore: list.length >= pageSize,
                            scrollLoading: listUntreatedScroll.size >= total
                        }
                    })
                }
                // 不为scroll类型
                else {
                    const setKey = (type && type === '未读') ? 'listUntreated' : 'listTotal'
                    yield put({
                        type: 'setState',
                        payload: {
                            [setKey]: list,
                            total,
                            currentPage,
                            pageSize,
                        }
                    });
                }
            } else {
                message.error(msg)
            }

            yield put({
                type: 'setState',
                payload: {
                    loading: false
                }
            })
            return
        },
        // 获取统计数量
        *getNoticeNum({ payload }, { call, put, select }) {
            const res = yield call(Axios.get, '/api/undoMsgNum')

            const {
                errno,
                msg,
                signOrderNum: signCount,
                claimCrmUserNum: claimCount,
                undoOrderAuditNum,
                lockOrderNum,
                undoFansAuditNum,
                undoLiveOrderNum,
                undoCrmUserPerfectNum,
                undoCrmUserBirthNum,
                undoCrmDelayNum
            } = res.data

            if (errno === 0) {

                yield put({
                    type: 'setState',
                    payload: {
                        noticeNums: {
                            signCount,
                            claimCount,
                            lockOrderNum,
                            undoOrderAuditNum,
                            undoFansAuditNum,
                            undoLiveOrderNum,
                            undoCrmUserPerfectNum,
                            undoCrmUserBirthNum,
                            undoCrmDelayNum
                        }
                    }
                })
            } else {
                message.error(msg)
            }
        },
        // 获取用户信息
        *getCrmInfo({ payload }, { call, put, select }) {
            const { crm_uid, oid, notice_id } = payload

            const res =
                yield call(
                    Axios.get,
                    '/api/manage/crm-users/detail',
                    {
                        params: {
                            crm_uid,
                            notice_id
                        }
                    }
                )

            const { errno, data, msg } = res.data
            if (errno !== 0) {
                message.error(msg)
            }
            yield put({
                type: 'setState',
                payload: {
                    crmInfo: errno === 0 ? data : null
                }
            })


            // 获取复购销售列表
            const reRes =
                yield call(
                    Axios.get,
                    '/api/manage/sign-orders/match-repurchase',
                    {
                        params: {
                            oid
                        }
                    }
                )

            const { errno: reErrno, msg: reMsg, data: reData } = reRes.data
            if (reErrno !== 0) {
                message.error(reMsg)
            }
            yield put({
                type: 'setState',
                payload: {
                    repurchaseData: reErrno === 0 ? reData : []
                }
            })
        }
    },
    reducers: {
        setState(state, { payload }) {
            return Object.assign({}, state, payload);
        },
    }
}