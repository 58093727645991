import axios from 'axios';
import { message } from 'antd';
export default {
  namespace: 'goods',
  state: [
  ],
  effects: {
    *loadGoods({ payload }, { call, put }) {
      const res = yield call(axios.get, '/yaoyu/items');
      if(res.data.errno === 0) {
        return yield put({
          type: 'setGoods',
          payload: res.data.items
        });
      } else {
        message.error('无法加载商品列表：' + res.data.msg);
        throw new Error('无法加载商品列表：'+ res.data.msg);
      }
    }
  },
  reducers: {
    setGoods(state, { payload: goods }) {
      console.log(goods);
      return goods;
    }
  }
};