import _snakeCase from "lodash/snakeCase";
export function sleep(ms) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, ms);
  });
}
export function isConstructable(fn) {
  var constructableFunctionRegex = /^function\b\s[A-Z].*/;
  var classRegex = /^class\b/; // 有 prototype 并且 prototype 上有定义一系列非 constructor 属性，则可以认为是一个构造函数

  return fn.prototype && Object.getOwnPropertyNames(fn.prototype).filter(function (k) {
    return k !== 'constructor';
  }).length || constructableFunctionRegex.test(fn.toString()) || classRegex.test(fn.toString());
}
export function getDefaultTplWrapper(appName) {
  return function (tpl) {
    return "<div id=\"" + getWrapperId(appName) + "\">" + tpl + "</div>";
  };
}
export function getWrapperId(appName) {
  return "__qiankun_subapp_wrapper_for_" + _snakeCase(appName) + "__";
}

var globalExcludeAssetFilter = function globalExcludeAssetFilter() {
  return false;
};

export function getGlobalExcludeAssetFilter() {
  return globalExcludeAssetFilter;
}
export function setGlobalExcludeAssetFilter(filter) {
  globalExcludeAssetFilter = filter;
}