import { Button, Col, Icon, message, Tooltip, Timeline, Popover, Popconfirm, Select, Input } from "antd";
import React from "react";
import styles from './index.less'
import Axios from "axios";
import { connect } from 'dva';
import moment from 'moment'

import CopyIcon from './../../components/CopyIcon'
import FoldUnit from './drawerCusinfoFoldunit'
import PopoverImg from './../../components/PopoverImg'

const question_circle = require('./icon/question-circle.png')
const info_circle = require('./icon/info-circle.png')

const mapStateToProps = state => {
    return {
        crmInfo: state.notice.crmInfo,
        repurchaseData: state.notice.repurchaseData
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getCrmInfo: (payload) => {
            dispatch({
                type: 'notice/getCrmInfo',
                payload: { ...payload }
            })
        },
        reGetList: () => {
            dispatch({
                type: 'notice/reGetList'
            })
        },
    }
}

class DrawerCusInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectKfId: null,
            failReason: ''
        }
    }

    componentDidMount() {
        const { crmUid, oid, noticeId } = this.props
        this.props.getCrmInfo({
            crm_uid: crmUid,
            oid,
            notice_id: noticeId
        })
    }

    getType = (tar) => {
        if (Array.isArray(tar))
            return 'array'
        else
            return typeof tar
    }

    parseContentText = (key) => {

        const { crmInfo } = this.props
        // 没有key
        if (!key)
            return
        else if (!crmInfo)
            return

        const { crmUser } = crmInfo

        const keyType = this.getType(key)

        if (keyType === 'string') {

            // 拥有特殊规则的变量
            const rule = new Map([
                ['weight', (val) => {
                    const value = (val / 1000).toFixed(2)
                    return Number(value) && value !== NaN ? value : '-'
                }],
                ['firstOrder.price', (val) => {
                    const value = (val / 100).toFixed(2)
                    return Number(value) && value !== NaN ? value : '-'
                }],
                ['love_style', (val) => (val?.join('，'))]
            ])

            return key.replace(/%([\w\.\[\]0-9]+)%/g, (match, valueKey) => {
                const keyArr = valueKey.split('.')
                let value = null

                // 循环遍历进行变量筛查，遇到数组默认取第0个
                if (Array.isArray(keyArr)) {
                    for (let [index, item] of keyArr.entries()) {
                        if (index === 0) {
                            value = crmUser[item]
                        } else {
                            if (Array.isArray(value))
                                value = value[0]
                            if (!value)
                                break
                            value = value[item]
                        }
                    }
                }

                if (rule.has(valueKey))
                    value = rule.get(valueKey)(value)
                return value || '-'
            })
        }

        return null
    }

    parseContent = (content) => (
        Array.isArray(content) && (
            content.map(item => {
                const { title, tip, copy } = item
                const itemText = this.parseContentText(item.key)

                let tipTitle = undefined
                let tipWidth = undefined

                if (tip) {
                    if (typeof tip === 'object') {
                        const { title, width } = tip
                        tipTitle = title
                        tipWidth = width
                    }
                    else
                        tipTitle = tip
                }

                return (
                    <Col key={tipTitle} span={8} className={styles['unit-item']}>
                        <div className={styles['unit-item-title']}>
                            {title}
                            {
                                tip && (
                                    <Tooltip
                                        title={tipTitle || ''}
                                        overlayStyle={{ maxWidth: tipWidth }}
                                    >
                                        <img className={styles['unit-item-icon']} src={question_circle} alt="" />
                                    </Tooltip>
                                )
                            }
                        </div>
                        <div className={styles['unit-item-text']}>
                            {itemText}
                            {
                                copy && itemText && itemText !== '-' && (
                                    <CopyIcon content={itemText} />
                                )
                            }
                        </div>
                    </Col>
                )
            })
        ) || (
            content?.type === 'reactNodeList' && content.content
        ) || (
            content
        )
    )

    getRecord = (record) => {

        const { create_datetime, spu, content, level, type, reason, chatScreenShot, consultScreenShot } = record

        return (
            <div className={styles['record-container']}>
                {/* line 1 */}
                <div>
                    <span className={styles['record-time']}>{moment(create_datetime).format('YYYY-MM-DD')}</span>
                    <div className={styles['record-type']}>{type}</div>
                    <div className={styles['record-rank']}>{level}</div>
                </div>
                {/* line 2 */}
                <div>
                    <div className={styles['record-inner']}>
                        <div className={styles['record-title']}>咨询款式:</div>
                        <div>
                            {
                                spu && (
                                    <span>{spu}</span>
                                )
                            }
                            <div className={styles['record-image-container']}>
                                {
                                    consultScreenShot.map(item => (
                                        <PopoverImg
                                            placement="right"
                                            trigger="hover"
                                            src={item}
                                        >
                                            <img src={item} alt="" />
                                        </PopoverImg>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* line 3 */}
                <div>
                    <div className={styles['record-inner']}>
                        <div className={styles['record-title']}>沟通内容:</div>
                        <div>{content}</div>
                    </div>
                </div>
                {/* line 4 */}
                <div>
                    <div className={styles['record-inner']}>
                        <div className={styles['record-title']}>聊天截图:</div>
                        <div>
                            <div className={styles['record-image-container']} style={{ marginTop: '0' }}>
                                {
                                    chatScreenShot.map(item => (
                                        <PopoverImg
                                            placement="right"
                                            trigger="hover"
                                            src={item}
                                        >
                                            <img src={item} alt="" />
                                        </PopoverImg>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* line 5 */}
                <div>
                    <div className={styles['record-inner']}>
                        <div className={styles['record-title']}>未购买原因:</div>
                        <div>{reason}</div>
                    </div>
                </div>
            </div>
        )
    }

    getTimeLine = (records) => {

        return (
            <Timeline className={styles['cusinfo-timeline']}>
                {
                    records.map(item => (
                        <Timeline.Item>
                            {this.getRecord(item)}
                        </Timeline.Item>
                    ))
                }
            </Timeline>
        )
    }

    onKfChange = (value) => {
        this.setState({
            selectKfId: value
        })
    }

    onFail = () => {
        const { crmUid: crm_uid, hideDrawer, reGetList, noticeId: notice_id } = this.props
        const { failReason } = this.state
        if (!crm_uid) {
            message.error('参数缺失')
            return false
        } else if (!failReason) {
            message.error('失败原因必填')
            return false
        }

        Axios.get('/api/manage/sign-orders/import-fail', {
            params: {
                crm_uid,
                notice_id,
                reason: failReason
            }
        }).then(res => {
            const { errno, msg } = res.data
            if (errno === 0) {
                message.success('导入失败')
                reGetList()
                hideDrawer()
            }
            else
                message.error(msg)
        })

        return true
    }

    onSuccess = () => {
        const { crmUid: crm_uid, oid, hideDrawer, reGetList, repurchaseData, noticeId: notice_id } = this.props
        const { selectKfId: kf_account_id } = this.state
        if (!kf_account_id && kf_account_id !== 0) {
            message.error('微信客服必填')
            return false
        }

        const { kf_account_id: matchKfId, crm_uid: matchCrmUid } = repurchaseData.matchedCrmUser || {}
        Axios.get('/api/manage/sign-orders/import-success', {
            params: {
                oid: oid,
                crm_uid: matchCrmUid || undefined,
                kf_account_id: matchKfId || kf_account_id,
                notice_id
            }
        }).then(res => {
            const { errno, msg } = res.data
            if (errno === 0) {
                message.success('导入成功')
                reGetList()
                hideDrawer()
            }
            else
                message.error(msg)
        })

        return true
    }

    onFailReasonChange = (event) => {
        const { value } = event.target
        this.setState({
            failReason: value.length > 30 ? value.substring(0, 30) : value
        })
    }

    render() {

        const { failReason } = this.state

        const { repurchaseData, crmInfo } = this.props

        const { kfAccounts, matchedCrmUser } = repurchaseData || {}

        const { kf_name, saler_name } = crmInfo?.crmUser || {}

        const { kf_account_id: matchKfId, name: matchName, crm_uid: matchCrmUid } = matchedCrmUser || {}

        const { crmUser } = crmInfo || {}

        const infoList = [
            {
                unitTitle: '基本信息',
                // unitSubTitle: '',
                content: [
                    {
                        title: '客户名称',
                        key: '%name%'
                    }, {
                        title: '会员等级',
                        key: '%level%'
                    }, {
                        title: '消费排名',
                        key: '%rank%',
                        tip: {
                            title: '以当前会员买家ID纬度统计消费金额、消费排名',
                            width: 400
                        },
                    }, {
                        title: '微信名称',
                        key: '%wechat_name%'
                    }, {
                        title: '微信号',
                        key: '%wx_id%'
                    }, {
                        title: '手机号码',
                        key: '%phone%'
                    }, {
                        title: '身高体重',
                        key: '%height%cm/%weight%斤'
                    }, {
                        title: '加粉日期',
                        key: '%join_date%'
                    }, {
                        title: '所在城市',
                        key: '%addresses.city%'
                    }, {
                        title: '地址',
                        tip: '与会员资料里面收货地址一致',
                        key: '%addresses.province%%addresses.city%******'
                    }
                ],
                foldContent: [
                    {
                        title: '职业',
                        key: '%job%'
                    }, {
                        title: '生日',
                        key: '%birth_date%'
                    }, {
                        title: '年龄',
                        key: '%age%'
                    }, {
                        title: '身形',
                        key: '%shape%'
                    }, {
                        title: '肤色',
                        key: '%color_of_skin%'
                    }, {
                        title: '兴趣爱好',
                        key: '%hobbies%'
                    }
                ]
            },
            {
                unitTitle: '首购信息',
                unitSubTitle: '（客户在OMS购买的首购订单的信息情况）',
                content: [
                    {
                        title: '首购商品',
                        key: '%firstOrder.nos%',
                        copy: true
                    }, {
                        title: '首购金额',
                        key: '%firstOrder.price%'
                    }, {
                        title: '首购件数',
                        key: '%firstOrder.totalNum%'
                    }, {
                        title: '首购时间',
                        key: '%firstOrder.orderDate%'
                    }, {
                        title: '购买方式',
                        key: '%firstOrder.buy_method%'
                    }, {
                        title: '首购类型',
                        key: '%firstOrder.activity_type%'
                    }, {
                        title: '产品类型',
                        key: '%firstOrder.goods_type%'
                    }
                ]
            },
            {
                unitTitle: '消费喜好',
                content: [
                    {
                        title: '颜色偏好',
                        key: '%love_color%'
                    }, {
                        title: '款式喜好',
                        key: '%love_style%'
                    }, {
                        title: '面料偏好',
                        key: '%love_fabric%'
                    }
                ]
            },
            {
                unitTitle: '跟进记录',
                foldContent: {
                    type: 'reactNodeList',
                    content: this.getTimeLine(crmUser?.followRecords || [])
                }
            }
        ]

        const messageBar = (
            <div className={styles['message-bar']}>
                <img className={styles['message-icon']} src={info_circle} alt="" />
                客户信息表“确认导入”之前，新签销售与复购销售请务必确保已完成微信号的交接。
            </div>
        )

        const popConfirm = (child, { onConfirm, onCancel, content, title }) => {
            const { finalAction, final, row } = this.props

            const totalContent = (
                <div>
                    <div className={styles['popconfirm-title']}>{title}</div>
                    <div className={styles['popconfirm-content']}>
                        <div className={styles['popconfirm-icon']}>
                            <Icon type="exclamation-circle" theme="filled" />
                        </div>
                        <div className={styles['popconfirm-texts']}>
                            {content}
                        </div>
                    </div>
                </div>
            )

            return (
                <Popconfirm
                    overlayClassName="cusinfo-popconfirm"
                    getPopupContainer={e => e.parentNode}
                    title={totalContent}
                    onCancel={onCancel}
                    onConfirm={() => {
                        if (onConfirm()) finalAction(final, row, true)
                    }}
                    okText="确认"
                    cancelText="取消"
                    trigger="click"
                    placement="topRight"
                    arrowPointAtCenter
                >
                    {child}
                </Popconfirm>
            )
        }

        return (
            <div className={styles['cusinfo-container']}>
                {messageBar}
                {
                    infoList.map(item => {
                        const { unitTitle, unitSubTitle, content, foldContent } = item
                        return (
                            <FoldUnit
                                key={unitTitle}
                                title={unitTitle}
                                subTitle={unitSubTitle}
                                alwaysOn={this.parseContent(content)}
                                folded={this.parseContent(foldContent)}
                            />
                        )
                    })
                }
                <div className={styles['cusinfo-btns']}>
                    {popConfirm(
                        <Button className={styles['btn-normal']}>导入失败</Button>,
                        {
                            title: '导入失败',
                            onConfirm: this.onFail,
                            content: (
                                <div>
                                    <div className={styles['popconfirm-line-title']}>确认“导入失败”后，此客户的订单不再归属你。</div>
                                    <div className={styles['popconfirm-subtitle']} style={{ margin: '16px 0' }}>新签客服：{saler_name}{saler_name && kf_name ? '｜' : ''}{kf_name}</div>
                                    <div className={styles['popconfirm-subtitle--textarea']}>
                                        <span>失败原因：</span>
                                        <Input.TextArea
                                            placeholder="请输入失败原因，最多30个字"
                                            className={styles['popconfirm-textarea']}
                                            value={failReason}
                                            onChange={this.onFailReasonChange}
                                        />
                                    </div>
                                    <div className={styles['textarea-count']} style={{ paddingRight: 11 }}>{failReason.length ?? 0}/30</div>
                                </div>
                            )
                        }
                    )}
                    {popConfirm(
                        <Button className={styles['btn-primary']} type="primary">导入成功</Button>,
                        {
                            title: '导入成功',
                            onConfirm: this.onSuccess,
                            content: !!matchKfId ? (
                                <div>
                                    <div className={styles['popconfirm-line-title']}>经过系统对比,已存在以下会员信息为同一个客户：</div>
                                    <div className={styles['popconfirm-subtitle']}>会员ID：{matchCrmUid}</div>
                                    <div className={styles['popconfirm-subtitle']} style={{ marginTop: 16 }}>微信客服：{matchName}</div>
                                    <div className={styles['popconfirm-subtitle']} style={{ marginTop: 16 }}>新签客服：{saler_name}{saler_name && kf_name ? '｜' : ''}{kf_name}</div>
                                </div>
                            ) : (
                                <div>
                                    <div className={styles['popconfirm-line-title']} > 新生成会员会关联你选择的微信客服。</div>
                                    <div className={styles['popconfirm-subtitle']} style={{ margin: '16px 0' }}>新签客服：{saler_name}{saler_name && kf_name ? '｜' : ''}{kf_name}</div>
                                    <div style={{ display: 'flex' }}>
                                        <div className={styles['popconfirm-subtitle']}>微信客服：</div>
                                        <Select
                                            className={styles['popconfirm-select']}
                                            placeholder="请选择微信客服"
                                            onChange={this.onKfChange}
                                            allowClear
                                        >
                                            {kfAccounts?.map(item => (
                                                <Select.Option key={item.kf_account_id} value={item.kf_account_id}>{item.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            )
                        }
                    )
                    }
                </div >
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawerCusInfo)