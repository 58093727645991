import axios from 'axios';

/**
 * 微信客服模糊搜索
 * @param {string} keyword 搜索关键字
 */
export function getAccounts(keyword) {
  return axios.get(`/api/manage/kf-accounts/search?keyword=${keyword}`);
}

/**
 * 销售列表模糊搜索
 * @param {string} name 搜索关键字
 * @param {string} is_sale 是否正常状态，1:所有 2:正常状态
 */
export function getSaleUsers({ name, is_sale }) {
  return axios.get(`/api/manage/users/search?name=${name}&is_sale=${is_sale}`);
}

/**
 * 获取支付方式
 */
export function getPayments() {
  return axios.get('/api/manage/shop/payments');
}
