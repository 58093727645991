import { Icon, Tooltip } from 'antd'
import styles from './index.less'

export default class CopyIcon extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            action: false,
            isTipVisible: false
        }
    }

    render() {

        const { content } = this.props
        const { action, isTipVisible } = this.state

        if (!content)
            return null

        return (
            <span>
                <span
                    className={`${styles['action-icon']} action-icon-global-cls`}
                    data-success={action}
                    onClick={() => {
                        this.setState({ action: true, isTipVisible: true })
                        setTimeout(() => {
                            this.setState({ isTipVisible: false })
                        }, 1000);
                        setTimeout(() => {
                            this.setState({ action: false })
                        }, 3000);
                    }}
                >
                    {
                        this.state.action ?
                            (
                                <Tooltip title="复制成功" placement="top" visible={isTipVisible}>
                                    <Icon type="check" />
                                </Tooltip>
                            ) : (
                                <Icon
                                    type="copy"
                                    onClick={() => {
                                        let input = document.createElement('input')
                                        input.classList.add('action-icon-copy-input')
                                        document.body.append(input)
                                        input.value = content
                                        input.select()
                                        document.execCommand("copy")
                                        document.body.removeChild(input)
                                    }}
                                />
                            )
                    }
                </span>
            </span>
        )
    }
}