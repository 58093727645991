import axios from 'axios';
import { message } from 'antd';
export default {
  namespace: 'liveFormCard',
  state: {
    cardList:{},
    liveId:0
  },
  effects: {

  },
  reducers: {
    setState(state, { payload }) {
        console.log(payload)
        return Object.assign({}, state, payload);
    }
  }
};