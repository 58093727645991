/**
 * @author Kuitos
 * @since 2019-04-11
 */
import hijackDynamicAppend from './dynamicHeadAppend';
import hijackHistoryListener from './historyListener';
import hijackTimer from './timer';
import hijackWindowListener from './windowListener';
export function hijackAtMounting(appName, proxy, execScriptsOpts) {
  return [hijackTimer(), hijackWindowListener(), hijackHistoryListener(), hijackDynamicAppend(appName, proxy, true, execScriptsOpts)];
}
export function hijackAtBootstrapping(appName, proxy, execScriptsOpts) {
  return [hijackDynamicAppend(appName, proxy, false, execScriptsOpts)];
}