import axios from 'axios';
import { message } from 'antd';

export default {
  namespace: 'accounts',
  state: [ ],
  effects: {
    *loadAccounts({ payload }, { call, put }) {
      const res = yield call(axios.get, '/yaoyu/getSales');
      if(res.data.errno === 0) {
        return yield put({
          type: 'setUsers',
          payload: res.data.users
        });
      } else {
        message.error('无法加载销售列表：' + res.data.msg);
      }
    }
  },
  reducers: {
    setUsers(state, { payload: users }) {
      console.log(users);
      return users;
    }
  }
}