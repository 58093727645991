import _isFunction from "lodash/isFunction";

/**
 * @author Kuitos
 * @since 2019-10-21
 */
import { execScripts } from 'import-html-entry';
import { checkActivityFunctions } from 'single-spa';
import { getWrapperId, getGlobalExcludeAssetFilter } from '../utils';
var styledComponentSymbol = Symbol('styled-component');
var rawHeadAppendChild = HTMLHeadElement.prototype.appendChild;
var rawHeadRemoveChild = HTMLHeadElement.prototype.removeChild;
var rawAppendChild = HTMLElement.prototype.appendChild;
var rawRemoveChild = HTMLElement.prototype.removeChild;
var SCRIPT_TAG_NAME = 'SCRIPT';
var LINK_TAG_NAME = 'LINK';
var STYLE_TAG_NAME = 'STYLE';
/**
 * Check if a style element is a styled-component liked.
 * A styled-components liked element is which not have textContext but keep the rules in its styleSheet.cssRules.
 * Such as the style element generated by styled-components and emotion.
 * @param element
 */

function isStyledComponentsLike(element) {
  var _a, _b;

  return !element.textContent && (((_a = element.sheet) === null || _a === void 0 ? void 0 : _a.cssRules.length) || ((_b = getCachedRules(element)) === null || _b === void 0 ? void 0 : _b.length));
}

function getCachedRules(element) {
  return element[styledComponentSymbol];
}

function setCachedRules(element, cssRules) {
  Object.defineProperty(element, styledComponentSymbol, {
    value: cssRules,
    configurable: true,
    enumerable: false
  });
}

function assertElementExist(appName, element) {
  if (!element) throw new Error("[qiankun]: " + appName + " wrapper with id " + getWrapperId(appName) + " not ready!");
}

function getWrapperElement(appName) {
  return document.getElementById(getWrapperId(appName));
}
/**
 * Just hijack dynamic head append, that could avoid accidentally hijacking the insertion of elements except in head.
 * Such a case: ReactDOM.createPortal(<style>.test{color:blue}</style>, container),
 * this could made we append the style element into app wrapper but it will cause an error while the react portal unmounting, as ReactDOM could not find the style in body children list.
 * @param appName
 * @param proxy
 * @param mounting
 */


export default function hijack(appName, proxy, mounting, execScriptsOpts) {
  if (mounting === void 0) {
    mounting = true;
  }

  var dynamicStyleSheetElements = [];

  HTMLHeadElement.prototype.appendChild = function appendChild(newChild) {
    var element = newChild;

    if (element.tagName) {
      switch (element.tagName) {
        case LINK_TAG_NAME:
        case STYLE_TAG_NAME:
          {
            var stylesheetElement = newChild; // check if the currently specified application is active
            // While we switch page from qiankun app to a normal react routing page, the normal one may load stylesheet dynamically while page rendering,
            // but the url change listener must to wait until the current call stack is flushed.
            // This scenario may cause we record the stylesheet from react routing page dynamic injection,
            // and remove them after the url change triggered and qiankun app is unmouting
            // see https://github.com/ReactTraining/history/blob/master/modules/createHashHistory.js#L222-L230

            var activated = checkActivityFunctions(window.location).some(function (name) {
              return name === appName;
            }); // only hijack dynamic style injection when app activated

            if (activated) {
              var href = stylesheetElement.href;

              if (href && getGlobalExcludeAssetFilter()(href)) {
                break;
              }

              dynamicStyleSheetElements.push(stylesheetElement);
              var appWrapper = getWrapperElement(appName);
              assertElementExist(appName, appWrapper);
              return rawAppendChild.call(appWrapper, stylesheetElement);
            }

            return rawHeadAppendChild.call(this, element);
          }

        case SCRIPT_TAG_NAME:
          {
            var _a = element,
                src = _a.src,
                text = _a.text;

            if (src && getGlobalExcludeAssetFilter()(src)) {
              break;
            }

            if (src) {
              execScripts(null, [src], proxy, execScriptsOpts).then(function () {
                // we need to invoke the onload event manually to notify the event listener that the script was completed
                // here are the two typical ways of dynamic script loading
                // 1. element.onload callback way, which webpack and loadjs used, see https://github.com/muicss/loadjs/blob/master/src/loadjs.js#L138
                // 2. addEventListener way, which toast-loader used, see https://github.com/pyrsmk/toast/blob/master/src/Toast.ts#L64
                var loadEvent = new CustomEvent('load');

                if (_isFunction(element.onload)) {
                  element.onload(loadEvent);
                } else {
                  element.dispatchEvent(loadEvent);
                }
              }, function () {
                var errorEvent = new CustomEvent('error');

                if (_isFunction(element.onerror)) {
                  element.onerror(errorEvent);
                } else {
                  element.dispatchEvent(errorEvent);
                }
              });
              var dynamicScriptCommentElement = document.createComment("dynamic script " + src + " replaced by qiankun");
              var appWrapper_1 = getWrapperElement(appName);
              assertElementExist(appName, appWrapper_1);
              return rawAppendChild.call(appWrapper_1, dynamicScriptCommentElement);
            }

            execScripts(null, ["<script>" + text + "</script>"], proxy, execScriptsOpts).then(element.onload, element.onerror);
            var dynamicInlineScriptCommentElement = document.createComment('dynamic inline script replaced by qiankun');
            var appWrapper = getWrapperElement(appName);
            assertElementExist(appName, appWrapper);
            return rawAppendChild.call(appWrapper, dynamicInlineScriptCommentElement);
          }

        default:
          break;
      }
    }

    return rawHeadAppendChild.call(this, element);
  };

  HTMLHeadElement.prototype.removeChild = function removeChild(child) {
    var appWrapper = getWrapperElement(appName);

    if (appWrapper === null || appWrapper === void 0 ? void 0 : appWrapper.contains(child)) {
      return rawRemoveChild.call(appWrapper, child);
    }

    return rawHeadRemoveChild.call(this, child);
  };

  return function free() {
    HTMLHeadElement.prototype.appendChild = rawHeadAppendChild;
    HTMLHeadElement.prototype.removeChild = rawHeadRemoveChild;
    dynamicStyleSheetElements.forEach(function (stylesheetElement) {
      /*
         With a styled-components generated style element, we need to record its cssRules for restore next re-mounting time.
         We're doing this because the sheet of style element is going to be cleaned automatically by browser after the style element dom removed from document.
         see https://www.w3.org/TR/cssom-1/#associated-css-style-sheet
         */
      if (stylesheetElement instanceof HTMLStyleElement && isStyledComponentsLike(stylesheetElement)) {
        if (stylesheetElement.sheet) {
          // record the original css rules of the style element for restore
          setCachedRules(stylesheetElement, stylesheetElement.sheet.cssRules);
        }
      } // As now the sub app content all wrapped with a special id container,
      // the dynamic style sheet would be removed automatically while unmoutting

    });
    return function rebuild() {
      dynamicStyleSheetElements.forEach(function (stylesheetElement) {
        // re-append the dynamic stylesheet to sub-app container
        var appWrapper = getWrapperElement(appName);
        assertElementExist(appName, appWrapper); // Using document.head.appendChild ensures that appendChild calls
        // can also directly use the HTMLHeadElement.prototype.appendChild method which is overwritten at mounting phase

        document.head.appendChild.call(appWrapper, stylesheetElement);
        /*
        get the stored css rules from styled-components generated element, and the re-insert rules for them.
        note that we must do this after style element had been added to document, which stylesheet would be associated to the document automatically.
        check the spec https://www.w3.org/TR/cssom-1/#associated-css-style-sheet
         */

        if (stylesheetElement instanceof HTMLStyleElement && isStyledComponentsLike(stylesheetElement)) {
          var cssRules = getCachedRules(stylesheetElement);

          if (cssRules) {
            // eslint-disable-next-line no-plusplus
            for (var i = 0; i < cssRules.length; i++) {
              var cssRule = cssRules[i];
              stylesheetElement.sheet.insertRule(cssRule.cssText);
            }
          }
        }
      }); // As the hijacker will be invoked every mounting phase, we could release the cache for gc after rebuilding

      if (mounting) {
        dynamicStyleSheetElements = [];
      }
    };
  };
}