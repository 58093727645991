import moment from 'moment';

export default {
  namespace: 'eventLog',
  state:{
    tableDateRanges: [ moment().startOf('isoWeek'), moment() ],
    uid: 0,
    pagination: {},
    data: []
  },
  reducers: {
    setState(state, { payload }) {
      return Object.assign({}, state, payload);
    },
  }
}