import _isFunction from "lodash/isFunction";
import _concat from "lodash/concat";
import _mergeWith from "lodash/mergeWith";
import _flow from "lodash/flow";
import _identity from "lodash/identity";
import { __assign, __awaiter, __generator, __read, __rest, __spreadArray } from "tslib";
import { importEntry } from 'import-html-entry';
import { registerApplication, start as startSingleSpa } from 'single-spa';
import getAddOns from './addons';
import { prefetchAfterFirstMounted, prefetchAll } from './prefetch';
import { genSandbox } from './sandbox';
import { getDefaultTplWrapper, setGlobalExcludeAssetFilter } from './utils';
var microApps = [];

function toArray(array) {
  return Array.isArray(array) ? array : [array];
}

function execHooksChain(hooks, app) {
  if (hooks.length) {
    return hooks.reduce(function (chain, hook) {
      return chain.then(function () {
        return hook(app);
      });
    }, Promise.resolve());
  }

  return Promise.resolve();
}

function validateSingularMode(validate, app) {
  return __awaiter(this, void 0, void 0, function () {
    return __generator(this, function (_a) {
      return [2
      /*return*/
      , typeof validate === 'function' ? validate(app) : !!validate];
    });
  });
}

var Deferred =
/** @class */
function () {
  function Deferred() {
    var _this = this;

    this.promise = new Promise(function (resolve, reject) {
      _this.resolve = resolve;
      _this.reject = reject;
    });
  }

  return Deferred;
}();
/*
 * with singular mode, any app will wait to load until other apps are unmouting
 * it is useful for the scenario that only one sub app shown at one time
 */


var singularMode = false;
var useJsSandbox = false;
var frameworkStartedDefer = new Deferred();
export function registerMicroApps(apps, lifeCycles, opts) {
  var _this = this;

  window.__POWERED_BY_QIANKUN__ = true; // Each app only needs to be registered once

  var unregisteredApps = apps.filter(function (app) {
    return !microApps.some(function (registeredApp) {
      return registeredApp.name === app.name;
    });
  });
  microApps = __spreadArray(__spreadArray([], __read(microApps)), __read(unregisteredApps));
  var prevAppUnmountedDeferred;
  unregisteredApps.forEach(function (app) {
    var name = app.name,
        entry = app.entry,
        render = app.render,
        activeRule = app.activeRule,
        _a = app.props,
        props = _a === void 0 ? {} : _a;
    registerApplication(name, function (_a) {
      var appName = _a.name;
      return __awaiter(_this, void 0, void 0, function () {
        var _b, _c, getTemplate, settings, _d, appContent, execScripts, assetPublicPath, jsSandbox, mountSandbox, unmountSandbox, fetch_1, sandbox, _e, _f, beforeUnmount, _g, afterUnmount, _h, afterMount, _j, beforeMount, _k, beforeLoad, _l, bootstrapApp, mount, unmount, globalVariableExports;

        var _this = this;

        return __generator(this, function (_m) {
          switch (_m.label) {
            case 0:
              return [4
              /*yield*/
              , frameworkStartedDefer.promise];

            case 1:
              _m.sent();

              _b = opts || {}, _c = _b.getTemplate, getTemplate = _c === void 0 ? _identity : _c, settings = __rest(_b, ["getTemplate"]);
              return [4
              /*yield*/
              , importEntry(entry, __assign({
                // compose the config getTemplate function with default wrapper
                getTemplate: _flow(getTemplate, getDefaultTplWrapper(appName))
              }, settings))];

            case 2:
              _d = _m.sent(), appContent = _d.template, execScripts = _d.execScripts, assetPublicPath = _d.assetPublicPath;
              return [4
              /*yield*/
              , validateSingularMode(singularMode, app)];

            case 3:
              if (!_m.sent()) return [3
              /*break*/
              , 5];
              return [4
              /*yield*/
              , prevAppUnmountedDeferred && prevAppUnmountedDeferred.promise];

            case 4:
              _m.sent();

              _m.label = 5;

            case 5:
              // 第一次加载设置应用可见区域 dom 结构
              // 确保每次应用加载前容器 dom 结构已经设置完毕
              render({
                appContent: appContent,
                loading: true
              });
              jsSandbox = window;

              mountSandbox = function mountSandbox() {
                return Promise.resolve();
              };

              unmountSandbox = function unmountSandbox() {
                return Promise.resolve();
              };

              if (useJsSandbox) {
                fetch_1 = (settings || {}).fetch;
                sandbox = genSandbox(appName, {
                  fetch: fetch_1
                });
                jsSandbox = sandbox.sandbox;
                mountSandbox = sandbox.mount;
                unmountSandbox = sandbox.unmount;
              }

              _e = _mergeWith({}, getAddOns(jsSandbox, assetPublicPath), lifeCycles, function (v1, v2) {
                return _concat(v1 !== null && v1 !== void 0 ? v1 : [], v2 !== null && v2 !== void 0 ? v2 : []);
              }), _f = _e.beforeUnmount, beforeUnmount = _f === void 0 ? [] : _f, _g = _e.afterUnmount, afterUnmount = _g === void 0 ? [] : _g, _h = _e.afterMount, afterMount = _h === void 0 ? [] : _h, _j = _e.beforeMount, beforeMount = _j === void 0 ? [] : _j, _k = _e.beforeLoad, beforeLoad = _k === void 0 ? [] : _k;
              return [4
              /*yield*/
              , execHooksChain(toArray(beforeLoad), app)];

            case 6:
              _m.sent();

              return [4
              /*yield*/
              , execScripts(jsSandbox)];

            case 7:
              _l = _m.sent(), bootstrapApp = _l.bootstrap, mount = _l.mount, unmount = _l.unmount;

              if (!_isFunction(bootstrapApp) || !_isFunction(mount) || !_isFunction(unmount)) {
                if (process.env.NODE_ENV === 'development') {
                  console.warn("LifeCycles are not found from " + appName + " entry exports, fallback to get them from window['" + appName + "'] ");
                }

                globalVariableExports = window[appName] || {};
                bootstrapApp = globalVariableExports.bootstrap; // eslint-disable-next-line prefer-destructuring

                mount = globalVariableExports.mount; // eslint-disable-next-line prefer-destructuring

                unmount = globalVariableExports.unmount;

                if (!_isFunction(bootstrapApp) || !_isFunction(mount) || !_isFunction(unmount)) {
                  throw new Error("You need to export the functional lifecycles in " + appName + " entry");
                }
              }

              return [2
              /*return*/
              , {
                bootstrap: [bootstrapApp],
                mount: [function () {
                  return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                      switch (_a.label) {
                        case 0:
                          return [4
                          /*yield*/
                          , validateSingularMode(singularMode, app)];

                        case 1:
                          if (_a.sent() && prevAppUnmountedDeferred) {
                            return [2
                            /*return*/
                            , prevAppUnmountedDeferred.promise];
                          }

                          return [2
                          /*return*/
                          , undefined];
                      }
                    });
                  });
                }, // 添加 mount hook, 确保每次应用加载前容器 dom 结构已经设置完毕
                function () {
                  return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                      return [2
                      /*return*/
                      , render({
                        appContent: appContent,
                        loading: true
                      })];
                    });
                  });
                }, // exec the chain after rendering to keep the behavior with beforeLoad
                function () {
                  return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                      return [2
                      /*return*/
                      , execHooksChain(toArray(beforeMount), app)];
                    });
                  });
                }, mountSandbox, mount, // 应用 mount 完成后结束 loading
                function () {
                  return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                      return [2
                      /*return*/
                      , render({
                        appContent: appContent,
                        loading: false
                      })];
                    });
                  });
                }, function () {
                  return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                      return [2
                      /*return*/
                      , execHooksChain(toArray(afterMount), app)];
                    });
                  });
                }, // initialize the unmount defer after app mounted and resolve the defer after it unmounted
                function () {
                  return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                      switch (_a.label) {
                        case 0:
                          return [4
                          /*yield*/
                          , validateSingularMode(singularMode, app)];

                        case 1:
                          if (_a.sent()) {
                            prevAppUnmountedDeferred = new Deferred();
                          }

                          return [2
                          /*return*/
                          ];
                      }
                    });
                  });
                }],
                unmount: [function () {
                  return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                      return [2
                      /*return*/
                      , execHooksChain(toArray(beforeUnmount), app)];
                    });
                  });
                }, unmount, unmountSandbox, function () {
                  return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                      return [2
                      /*return*/
                      , execHooksChain(toArray(afterUnmount), app)];
                    });
                  });
                }, // remove the app content after unmount
                function () {
                  return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                      return [2
                      /*return*/
                      , render({
                        appContent: '',
                        loading: false
                      })];
                    });
                  });
                }, function () {
                  return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                      switch (_a.label) {
                        case 0:
                          return [4
                          /*yield*/
                          , validateSingularMode(singularMode, app)];

                        case 1:
                          if (_a.sent() && prevAppUnmountedDeferred) {
                            prevAppUnmountedDeferred.resolve();
                          }

                          return [2
                          /*return*/
                          ];
                      }
                    });
                  });
                }]
              }];
          }
        });
      });
    }, activeRule, props);
  });
}
export function start(opts) {
  if (opts === void 0) {
    opts = {};
  }

  var _a = opts.prefetch,
      prefetch = _a === void 0 ? true : _a,
      _b = opts.jsSandbox,
      jsSandbox = _b === void 0 ? true : _b,
      _c = opts.singular,
      singular = _c === void 0 ? true : _c,
      excludeAssetFilter = opts.excludeAssetFilter,
      importEntryOpts = __rest(opts, ["prefetch", "jsSandbox", "singular", "excludeAssetFilter"]);

  switch (prefetch) {
    case true:
      prefetchAfterFirstMounted(microApps, importEntryOpts);
      break;

    case 'all':
      prefetchAll(microApps, importEntryOpts);
      break;

    default:
      break;
  }

  if (jsSandbox) {
    useJsSandbox = jsSandbox;
  }

  if (singular) {
    singularMode = singular;
  }

  if (excludeAssetFilter) {
    setGlobalExcludeAssetFilter(excludeAssetFilter);
  }

  startSingleSpa();
  frameworkStartedDefer.resolve();
}