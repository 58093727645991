import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import { routerRedux, dynamic as _dvaDynamic } from 'dva';

const Router = routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/signin-failed',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__signinFailed" */ '../signinFailed'),
        })
      : require('../signinFailed').default,
    exact: true,
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layout" */ '../../layout'),
        })
      : require('../../layout').default,
    routes: [
      {
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__buy" */ '../buy'),
            })
          : require('../buy').default,
        exact: true,
      },
      {
        path: '/buy',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__buy" */ '../buy'),
            })
          : require('../buy').default,
        exact: true,
      },
      {
        path: '/home',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__home" */ '../home'),
            })
          : require('../home').default,
        exact: true,
      },
      {
        path: '/createOrder',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__createOrder" */ '../createOrder'),
            })
          : require('../createOrder').default,
        exact: true,
      },
      {
        path: '/modifyOrder',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__modifyOrder" */ '../modifyOrder'),
            })
          : require('../modifyOrder').default,
        exact: true,
      },
      {
        path: '/orders',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__orders" */ '../orders'),
            })
          : require('../orders').default,
        exact: true,
      },
      {
        path: '/result',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__result" */ '../result'),
            })
          : require('../result').default,
        exact: true,
      },
      {
        path: '/kfAccounts',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__manage__kfAccounts" */ '../manage/kfAccounts'),
            })
          : require('../manage/kfAccounts').default,
        exact: true,
      },
      {
        path: '/manage/saleAccounts',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__manage__saleAccounts" */ '../manage/saleAccounts'),
            })
          : require('../manage/saleAccounts').default,
        exact: true,
      },
      {
        path: '/manage/voices',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__manage__voices" */ '../manage/voices'),
            })
          : require('../manage/voices').default,
        exact: true,
      },
      {
        path: '/manage/departments',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__manage__departments" */ '../manage/departments'),
            })
          : require('../manage/departments').default,
        exact: true,
      },
      {
        path: '/sign-in',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__sign-in" */ '../sign-in'),
            })
          : require('../sign-in').default,
        exact: true,
      },
      {
        path: '/imageUpload',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__imageUpload" */ '../imageUpload'),
            })
          : require('../imageUpload').default,
        exact: true,
      },
      {
        path: '/password',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__password" */ '../password'),
            })
          : require('../password').default,
        exact: true,
      },
      {
        path: '/cs',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import(/* webpackChunkName: "p__cs" */ '../cs'),
            })
          : require('../cs').default,
        exact: true,
      },
      {
        path: '/csOrders',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__csOrders" */ '../csOrders'),
            })
          : require('../csOrders').default,
        exact: true,
      },
      {
        path: '/maintenanceRecord/maintenanceRecord',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__maintenanceRecord__maintenanceRecord" */ '../maintenanceRecord/maintenanceRecord'),
            })
          : require('../maintenanceRecord/maintenanceRecord').default,
        exact: true,
      },
      {
        path: '/eventLog',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__eventLog" */ '../eventLog'),
            })
          : require('../eventLog').default,
        exact: true,
      },
      {
        path: '/repurchase',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__repurchase" */ '../repurchase'),
            })
          : require('../repurchase').default,
        exact: true,
      },
      {
        path: '/manage/products',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__manage__products" */ '../manage/products'),
            })
          : require('../manage/products').default,
        exact: true,
      },
      {
        path: '/manage/suppliers',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__supplier" */ '../supplier'),
            })
          : require('../supplier').default,
        exact: true,
      },
      {
        path: '/data-manage/fans-reporting',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__DataManage__FansReporting" */ '../DataManage/FansReporting'),
            })
          : require('../DataManage/FansReporting').default,
        exact: true,
      },
      {
        path: '/manage/crmusers',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__crm__index" */ '../crm/index'),
            })
          : require('../crm/index').default,
        exact: true,
      },
      {
        path: '/manage/crmuser-add',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__crm__add__add" */ '../crm/add/add'),
            })
          : require('../crm/add/add').default,
        exact: true,
      },
      {
        path: '/manage/crmuser-statics',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__visualData__customerAndRFM" */ '../visualData/customerAndRFM'),
            })
          : require('../visualData/customerAndRFM').default,
        exact: true,
      },
      {
        path: '/manage/crmuser-statics-sale',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__crm__analysis__saleAnalysis" */ '../crm/analysis/saleAnalysis'),
            })
          : require('../crm/analysis/saleAnalysis').default,
        exact: true,
      },
      {
        path: '/manage/samples',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__sample__sample" */ '../sample/sample'),
            })
          : require('../sample/sample').default,
        exact: true,
      },
      {
        path: '/fansgmv',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__intoPowder__index" */ '../intoPowder/index'),
            })
          : require('../intoPowder/index').default,
        exact: true,
      },
      {
        path: '/manage/purchaseOrders',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__manage__purchaseOrders" */ '../manage/purchaseOrders'),
            })
          : require('../manage/purchaseOrders').default,
        exact: true,
      },
      {
        path: '/manage/newfans',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__crm__newFans__newFans" */ '../crm/newFans/newFans'),
            })
          : require('../crm/newFans/newFans').default,
        exact: true,
      },
      {
        path: '/manage/addfans',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__crm__newFans__addFans" */ '../crm/newFans/addFans'),
            })
          : require('../crm/newFans/addFans').default,
        exact: true,
      },
      {
        path: '/addOrders',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__workOrderManagement__addOrders" */ '../workOrderManagement/addOrders'),
            })
          : require('../workOrderManagement/addOrders').default,
        exact: true,
      },
      {
        path: '/workOrders',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__workOrderManagement__workOrders" */ '../workOrderManagement/workOrders'),
            })
          : require('../workOrderManagement/workOrders').default,
        exact: true,
      },
      {
        path: '/workOrderVisual',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__workOrderManagement__workOrderVisual" */ '../workOrderManagement/workOrderVisual'),
            })
          : require('../workOrderManagement/workOrderVisual').default,
        exact: true,
      },
      {
        path: '/liveForm',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__liveForm__index" */ '../liveForm/index'),
            })
          : require('../liveForm/index').default,
        exact: true,
      },
      {
        path: '/liveForm/detail',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__liveForm__detail" */ '../liveForm/detail'),
            })
          : require('../liveForm/detail').default,
        exact: true,
      },
      {
        path: '/liveForm/buy',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__liveForm__buy" */ '../liveForm/buy'),
            })
          : require('../liveForm/buy').default,
        exact: true,
      },
      {
        path: '/dailys',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__daily__dailys" */ '../daily/dailys'),
            })
          : require('../daily/dailys').default,
        exact: true,
      },
      {
        path: '/myDailys',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__daily__myDailys" */ '../daily/myDailys'),
            })
          : require('../daily/myDailys').default,
        exact: true,
      },
      {
        path: '/setDailys',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__daily__setDailys" */ '../daily/setDailys'),
            })
          : require('../daily/setDailys').default,
        exact: true,
      },
      {
        path: '/deliveryOverview',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__deliveryOverview" */ '../deliveryOverview'),
            })
          : require('../deliveryOverview').default,
        exact: true,
      },
      {
        path: '/productData',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__visualData__productsData" */ '../visualData/productsData'),
            })
          : require('../visualData/productsData').default,
        exact: true,
      },
      {
        path: '/balanceDetail',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__crm__balanceDetail__balanceDetail" */ '../crm/balanceDetail/balanceDetail'),
            })
          : require('../crm/balanceDetail/balanceDetail').default,
        exact: true,
      },
      {
        path: '/memberCharge',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__crm__memberCharge" */ '../crm/memberCharge'),
            })
          : require('../crm/memberCharge').default,
        exact: true,
      },
      {
        path: '/crm',
        microApp: 'f-oms-crm',
        exact: false,
      },
      {
        path: '/notificationCenter',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__notificationCenter" */ '../notificationCenter'),
            })
          : require('../notificationCenter').default,
        exact: true,
      },
      {
        path: '/accountData',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__manage__accountData" */ '../manage/accountData'),
            })
          : require('../manage/accountData').default,
        exact: true,
      },
      {
        path: '/manage/crmusers/detail',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__crm__crmDetail" */ '../crm/crmDetail'),
            })
          : require('../crm/crmDetail').default,
        exact: true,
      },
    ],
  },
];
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return <Router history={history}>{renderRoutes(routes, props)}</Router>;
  }
}
