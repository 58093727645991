import axios from 'axios';
import { message } from 'antd';

const defaultPageSize = 10;

export default {
  namespace: 'products',
  state: {
      list: [],
      pagination: {
        current: 1,
        pageSize: defaultPageSize
      },
      keyword: '',
      searching: false
  },
  effects: {
      * search({ params = {} }, { call, put, select }) {
          if(!params.keyword) {
            const keyword = yield select(state => state.products.keyword);
            params.keyword = keyword;
          }
          yield put({
            type: 'setState',
            searching: true
          });
          const res = yield call(axios.get, '/api/order/products/search', { params });
          yield put({
            type: 'setState',
            searching: false
          });
          if (res.data.errno === 0) {
            return yield put({
                type: 'setProducts',
                ...res.data
            });
          } else {
              message.error(res.data.msg);
          }
      }
  },
  reducers: {
    setProducts(state, { 
        products,
        count,
        page = 1,
        size = defaultPageSize
     }) {
        state.list = products;
        state.pagination = {
            current: parseInt(page),
            pageSize: size,
            total: count
        }
        return {
            ...state
        };
    },
    setState(state, newState) {
      Object.assign(state, newState);
      return {
        ...state
      }
    }
  }
}
  