import axios from 'axios';
import { message } from 'antd';
import router from 'umi/router';

const ALIYUN_BASE = "https://oms-cf.keqin-it.com/oms-service"
const BASE_IPS = {
  "oms.chunlinchic.com": `${ALIYUN_BASE}/chunlinchic`,
  "oms.kunlinchic.com": `${ALIYUN_BASE}/kunlinchic`,
  "oms.chaolaoda.cn": `${ALIYUN_BASE}/chaolaoda`,
  "oms.keqin-it.com": `${ALIYUN_BASE}/V`,
  "oms.siyu.fit": `${ALIYUN_BASE}/siyu`
}

function getAliyunUrl (env) {

  const HOST_NAME = window.location.hostname;

  let url = "";
  if (env.indexOf("prod") != -1) {
    url = BASE_IPS[HOST_NAME];
  } else {
    url = `${ALIYUN_BASE}/T`
  }

  return url;
}

const defaultShopId = localStorage.getItem('shop_id');
export default {
  namespace: 'session',
  state: {
    user: {},
    menus: [],
    isDefaultPassword: false,
    permissions: [],
    shop_id: defaultShopId ? parseInt(defaultShopId) : 1,
    backdate: 0,
    lockOrderTotalPrice: false,
    shops: [],
    company: '',
    loading: true,
    selfCheck:false,
    openPresaleOrder:false,
    liveAttributes:[],
    fansUserSocialPropertyConfig:[],
    editAllPrice:false,
    selectMenu:[],
    warZone:[],
    hideAddCrmEntrance:true,
    isReleaseRole:true,
    shouldChange:false,
    exportOrderUrl:'',
    interfaceRule:[],
    supportES: null,
    expressTemplateUrl: ''
  },
  effects: {
    *logout({ payload }, { call, put }) {
      const res = yield call(axios.get, '/logout');
      if(res.data.errno > 0) {
        return message.error(res.data.msg);
      } else {
        document.location.href = '/sign-in?next=' + encodeURIComponent(document.location.href);
      }
    },
    *syncUser({ payload }, { call, put }) {
      try {
        const res = yield call(axios.get, '/api/loginState');
        if(res.data.errno === 0) {
          let selectMenu = [];
          res.data.menus.map((item,index)=>{
            item.menus.map((ite,idx)=>{
              selectMenu.push(ite)
            })
          })
          yield put({
            type:'removedailyTitle',
            payload:{}
          });
          return yield put({
            type: 'setState',
            payload: {
              user: res.data.user,
              menus: res.data.menus,
              selectMenu:selectMenu,
              isDefaultPassword: res.data.isDefaultPassword,
              permissions: res.data.permissions,
              permitted_gid: res.data.user.permitted_gid,
              shop_id: res.data.user.shop_id,
              loading: false,
              shops: res.data.shops,
              env: res.data.env,
              backdate: res.data.backdate,
              lockOrderTotalPrice: res.data.lockOrderTotalPrice,
              modifyPrice: res.data.modifyPrice,
              productSynchShops: res.data.productSynchShops,
              company: res.data.company,
              showEncryptInfo: res.data.showEncryptInfo,
              signWithNoOrder: res.data.signWithNoOrder,
              isRePurchaseSaler: res.data.isRePurchaseSaler,
              claimCrmUser: res.data.claimCrmUser,
              financeCheck: res.data.financeCheck,
              calculateByOrderCreateTime: res.data.calculateByOrderCreateTime,
              printSellerRemark: res.data.printSellerRemark,
              shouldEnsureSameSupplier: res.data.shouldEnsureSameSupplier,
              supplierShouldAudit: res.data.supplierShouldAudit,
              orderAward: res.data.orderAward,
              delayDelivery: res.data.delayDelivery,
              selfCheck:res.data.selfCheck,
              openPresaleOrder:res.data.openPresaleOrder,
              upNotice:res.data.upNotice,
              searchShowForBuy:res.data.searchShowForBuy,
              aliyunFcBase: getAliyunUrl(res.data.env),
              workOrderIsShow:res.data.workOrderIsShow,
              userDingtalk: res.data.userDingtalk,
              liveAttributes:res.data.liveAttributes,
              fansUserSocialPropertyConfig:res.data.fansUserSocialPropertyConfig,
              editAllPrice:res.data.editAllPrice,
              warZone:res.data.warZone,
              hideAddCrmEntrance:res.data.hideAddCrmEntrance,
              isReleaseRole: res.data.isReleaseRole,
              exportOrderNumLimit:res.data.exportOrderNumLimit,
              exportOrderUrl:res.data.exportOrderUrl,
              sampleImportUrl:res.data.sampleImportUrl,
              interfaceRule: res.data.interfaceRule,
              supportES: res.data.supportES,
              expressTemplateUrl: res.data.expressTemplateUrl
            }
          });
        }
      }catch (e) {
        console.log(e);
      }
    }
  },
  reducers: {
    setState(state, { payload }) {
      return Object.assign({}, state, payload);
    },
    removedailyTitle(state, { payload }){//用户登陆的时候清除 dailyTitle 缓存，便于用户体验
        if(window.localStorage.getItem('dailyTitle')){
          window.localStorage.removeItem('dailyTitle')
        }
        return {
          ...state
        }
    }
  }
}