import _flow from "lodash/flow";
import _identity from "lodash/identity";

/**
 * @author Kuitos
 * @since 2019-02-26
 */
import { __assign, __awaiter, __generator, __rest } from "tslib";
import { importEntry } from 'import-html-entry';
import { getMountedApps } from 'single-spa';
import { getDefaultTplWrapper } from './utils'; // RIC and shim for browsers setTimeout() without it

var requestIdleCallback = window.requestIdleCallback || function requestIdleCallback(cb) {
  var start = Date.now();
  return setTimeout(function () {
    cb({
      didTimeout: false,
      timeRemaining: function timeRemaining() {
        return Math.max(0, 50 - (Date.now() - start));
      }
    });
  }, 1);
}; // https://stackoverflow.com/questions/3514784/what-is-the-best-way-to-detect-a-mobile-device


var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
var isSlowNetwork = navigator.connection ? navigator.connection.saveData || /(2|3)g/.test(navigator.connection.effectiveType) : false;
/**
 * prefetch assets, do nothing while in mobile network
 * @param appName
 * @param entry
 * @param opts
 */

function prefetch(appName, entry, opts) {
  var _this = this;

  if (isMobile || isSlowNetwork) {
    // Don't prefetch if an mobile device or in a slow network.
    return;
  }

  requestIdleCallback(function () {
    return __awaiter(_this, void 0, void 0, function () {
      var _a, _b, getTemplate, settings, _c, getExternalScripts, getExternalStyleSheets;

      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            _a = opts || {}, _b = _a.getTemplate, getTemplate = _b === void 0 ? _identity : _b, settings = __rest(_a, ["getTemplate"]);
            return [4
            /*yield*/
            , importEntry(entry, __assign({
              getTemplate: _flow(getTemplate, getDefaultTplWrapper(appName))
            }, settings))];

          case 1:
            _c = _d.sent(), getExternalScripts = _c.getExternalScripts, getExternalStyleSheets = _c.getExternalStyleSheets;
            requestIdleCallback(getExternalStyleSheets);
            requestIdleCallback(getExternalScripts);
            return [2
            /*return*/
            ];
        }
      });
    });
  });
}

export function prefetchAfterFirstMounted(apps, opts) {
  window.addEventListener('single-spa:first-mount', function () {
    var mountedApps = getMountedApps();
    var notMountedApps = apps.filter(function (app) {
      return mountedApps.indexOf(app.name) === -1;
    });

    if (process.env.NODE_ENV === 'development') {
      console.log("prefetch starting after " + mountedApps + " mounted...", notMountedApps);
    }

    notMountedApps.forEach(function (_a) {
      var name = _a.name,
          entry = _a.entry;
      return prefetch(name, entry, opts);
    });
  }, {
    once: true
  });
}
export function prefetchAll(apps, opts) {
  window.addEventListener('single-spa:no-app-change', function () {
    if (process.env.NODE_ENV === 'development') {
      console.log('prefetch starting for all assets...', apps);
    }

    apps.forEach(function (_a) {
      var name = _a.name,
          entry = _a.entry;
      return prefetch(name, entry, opts);
    });
  }, {
    once: true
  });
}