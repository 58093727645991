import axios from 'axios';
import { message } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import * as Sentry from '@sentry/browser';

async function parseAdress(address) {
  try {
    const result = await axios.get('/api/kuaidi/parse?address=' + encodeURIComponent(address));
    console.log('axios result', result);
    return result;
  } catch (e) {
    console.log('axios error', e);
    return {
      errno: 1,
      msg: e.message
    }
  }
}

async function getUserList(account) {
  return axios.get('/yaoyu/getUsersByKfAccount?account=' + encodeURIComponent(account));
}

const emptyState = {
  address: {},
  payType: '已付全款',
  prepayPrice: 0, //订金
  depositPrice: 0,//预售订金
  totalPrice: 0, //订单总金额
  price: 0, // 订单实际金额
  payment: '',
  account: '',
  uid: '',
  users: [],
  customerType: '',
  inputAddress: '',
  remark: '',
  sellerRemark: '',
  orderDate: moment(),
  deliveryDate: null,
  screenshot: [],
  screenshotExt: '',
  uploadId: [],
  img: [],
  joinDate: '',
  inputJoinDate: '',
  from_record_id: 0,
  parsingAddress: false,
  crmUid: 0,
  csType: '', // 换货、补发、维修
  mode: 'default', // 默认模式：全价；支持：补差价模式 diff
  recievedPrice: '', //实收金额
  source: "微信个号",
  recievedPriceRemark: '', // 实收备注
  award: '', // 订单激励
  canModifyPrice:true,
  liveOrderNo: '', // 直播间订单号
  is_use_balance_payment: "否",
  balance_payment: '',
  payTypeChosedList: [], // 选用的付款方式
  is_use_shopping_payment: "否", 
  shopping_payment: ''
};
export default {
  namespace: 'order',
  state: Object.assign({}, emptyState),
  effects: {
    *parseAdress({ payload }, effects) {
      const { address } = payload;
      const { call, put } = effects;
      if(!address) {
        return yield put({ type: 'setParsedAddress', payload: {
          address: '',
          inputAddress: ''
        } });
      }
      put({ type: 'setState', payload: { parsingAddress: true }});
      try {
        const res = yield call(parseAdress, address);
        put({ type: 'setState', payload: { parsingAddress: false }});
        if(res.data.code === 0) {
          return yield put({
            type: 'setParsedAddress', 
            payload: {
              inputAddress: address,
              address: res.data.data[0]
            }
          });
        } else {
          Sentry.withScope(scope => {
              scope.setExtras({
                address,
                res
              });
              Sentry.captureMessage('无法正确读取快递地址');
          });
          console.log(res, JSON.stringify(res));
          throw new Error(res.data.msg);
        }
      } catch (e) {
        console.log(e);
        message.error('无法正确读取快递地址：'+e.message);
      }
    },
    *loadExchangeOrder({ payload: record_id }, { call, put }) {
      const res = yield call(axios.get, '/yaoyu/getRecordContact?record_id=' + encodeURIComponent(record_id));
      if (res.data.errno > 0) {
        return message.error(res.data.msg);
      }
      const { kf_name: account, input_address: inputAddress, uid, type ,crm_uid} = res.data.order;
      const { mode } = res.data;
      yield put({  
        type: 'setState', 
        payload: {
          from_record_id: record_id,
          account,
          inputAddress,
          crm_uid,
          uid,
          csType: type,
          mode
        }
      });
      yield put({
        type: 'getUsersByKfAccount',
        payload: account
      });
    },
    *getUsersByKfAccount({payload: account}, { call, put }) {
      if(!account) {
        return yield put({
          type: 'setState', 
          payload: {
            uid: '',
            users: []
          }
        });
      }
      const res = yield call(getUserList, account);
      if(res.data.errno === 0) {
        let uid = 0;
        if(res.data.users.length > 0) {
          uid = res.data.users[0].uid;
        }
        let force = res.data.users.length === 1;
        yield put({
          type: 'setState', 
          payload: {
            users: res.data.users
          }
        });
        yield put({
          type: 'initUid',
          payload: {
            uid,
            force
          }
        });
      } else {
        throw new Error(res.data.msg);
      }
    }
  },
  reducers: {
    initUid(state, { payload }) {
      const { uid, force } = payload;
      /*
        location.pathname.includes('/createOrder')
        判断在下订单的页面进行客服的关联
      */
      if(!state.uid || force || location.pathname.includes('/createOrder')) {
        return Object.assign({}, state, { uid });
      } else {
        return Object.assign({}, state);
      }
    },
    removeRecordId(state, { payload }) {
      state.from_record_id = 0;
      state.csType = '';
      return Object.assign({}, state);
    },
    setParsedAddress(state, { payload }) {
      const { address, inputAddress } = payload;
      state.inputAddress = inputAddress;
      state.address = address;
      return Object.assign({}, state);
    },
    setPrepayPrice(state, { payload: price }) {
      state.prepayPrice = price;
      return Object.assign({}, state);
    },
    setDepositPrice(state, { payload: price }) {
      state.depositPrice = price;
      return { ...state };
    },
    setState(state, { payload }) {
      return Object.assign({}, state, payload);
    },
    reset(state) {
      emptyState.from_record_id = state.from_record_id;
      return Object.assign({}, emptyState);
    }
  }
}